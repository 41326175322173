var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('Fieldset',{attrs:{"id":"information","title":_vm.$t('Basic Information')}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Name') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Title')},on:{"input":function($event){return _vm.generateSlug(_vm.model.title)}},model:{value:(_vm.model.title),callback:function ($$v) {_vm.$set(_vm.model, "title", $$v)},expression:"model.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"*Slug:","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Slug","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Slug"},on:{"input":function($event){return _vm.generateSlug(_vm.model.slug)}},model:{value:(_vm.model.slug),callback:function ($$v) {_vm.$set(_vm.model, "slug", $$v)},expression:"model.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Point') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Point","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Point')},model:{value:(_vm.model.other),callback:function ($$v) {_vm.$set(_vm.model, "other", $$v)},expression:"model.other"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Point') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Point","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"width":"220px"},attrs:{"config":{
                  altFormat: 'd-m-Y',
                  dateFormat: 'Y-m-d',
                  altInput: true,
                },"placeholder":_vm.$t('Date')},model:{value:(_vm.model.note),callback:function ($$v) {_vm.$set(_vm.model, "note", $$v)},expression:"model.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Short content') + ":","label-cols-md":"12"}},[_c('b-form-textarea',{attrs:{"placeholder":_vm.$t('Short content'),"rows":"8"},model:{value:(_vm.model.short_content),callback:function ($$v) {_vm.$set(_vm.model, "short_content", $$v)},expression:"model.short_content"}})],1),_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Content') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('quill-editor',{ref:"qEdit",attrs:{"id":"content","options":_vm.snowOption,"state":errors.length > 0 ? false : null},model:{value:(_vm.model.content),callback:function ($$v) {_vm.$set(_vm.model, "content", $$v)},expression:"model.content"}}),_c('input',{attrs:{"id":"getFile","type":"file"},on:{"change":function($event){return _vm.uploadFunction($event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('Fieldset',{attrs:{"id":"SEO","title":_vm.$t('SEO Optimization')}},[_c('SEOInfo',{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"position-sticky top"},[_c('Fieldset',{attrs:{"id":"avatar","title":_vm.$t('Avatar')}},[_c('FeatureImage',{attrs:{"avatar":_vm.avatar},on:{"previewFiles":_vm.previewFiles}})],1),_c('Fieldset',{attrs:{"id":"status","title":_vm.$t('Status')}},[_c('b-form-group',{attrs:{"label":_vm.$t('Display on/off')+":","label-cols-md":"6"}},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"checked":"true","name":"check-button","switch":""},model:{value:(_vm.model.is_active),callback:function ($$v) {_vm.$set(_vm.model, "is_active", $$v)},expression:"model.is_active"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Position')+":","label-cols-md":"6"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":_vm.$t('Position')},model:{value:(_vm.model.position),callback:function ($$v) {_vm.$set(_vm.model, "position", $$v)},expression:"model.position"}})],1),_c('hr'),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Create new'))+" ")])],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }